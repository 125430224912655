<template>
  <sidebar-template title="Incident Details" :closeAction="closeAction">
    <template #content>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="d-flex flex-column align-items-start justify-content-start mt-0 w-100"
        >
          <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <!-- <label class="font-weight-bolder" pill>Name:</label> -->
            <h3 class="mb-0">{{ incident.title }}</h3>
          </div>

          <div
            class="d-flex align-items-center justify-content-between mb-2 w-100 border-bottom pb-75"
          >
            <div class="d-flex flex-row align-items-center justify-content-start">
              <label class="font-weight-bolder">Incident No:</label>
              <b-badge variant="primary">{{ incident.incident_register_number }}</b-badge>
            </div>

            <div class="d-flex flex-column align-items-start justify-content-start">
              <label class="font-weight-bolder">Incident Date:</label>
              <b-badge variant="secondary">{{ incident.incident_date | moment }}</b-badge>
            </div>

            <div class="d-flex flex-column align-items-start justify-content-start">
              <label class="font-weight-bolder">Closure Date:</label>
              <b-badge variant="warning">{{ incident.closure_date | moment }}</b-badge>
            </div>

            <div class="d-flex flex-column align-items-start justify-content-start">
              <label class="font-weight-bolder">Status:</label>
              <b-badge :variant="incident.status == 1 ? 'success' : 'danger'">{{
                incident.status == 1 ? "Open" : "Closed"
              }}</b-badge>
            </div>
          </div>

          <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <label class="font-weight-bolder">Description:</label>
            <p class="mb-0">{{ incident.description }}</p>
          </div>

          <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <label class="font-weight-bolder">Incident Owner:</label>
            <p class="font-weight-bold mb-0">
              {{ incident.incident_owner.firstname }}
              {{ incident.incident_owner.lastname }}
            </p>
          </div>

          <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <label class="font-weight-bolder">Root Cause:</label>
            <p class="mb-0">{{ incident.cause }}</p>
          </div>

          <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <label class="font-weight-bolder">Learning From The Incident:</label>
            <p class="mb-0">{{ incident.learning }}</p>
          </div>

          <div class="d-flex flex-column align-items-start justify-content-start mb-2">
            <label class="font-weight-bolder">Corrective Actions:</label>
            <div class="w-100 d-flex flex-column align-items-start justify-content-start">
              <ul>
                <template v-for="c in incident.corrective_actions">
                  <li :key="c">
                    {{ c }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </b-overlay>
    </template>
  </sidebar-template>
</template>

<script>
import { BOverlay, BBadge, BButton } from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import IncidentMixins from "@/mixins/IncidentMixins";
import moment from "moment";

import SidebarTemplate from "@/components/SidebarTemplate.vue";
export default {
  mixins: [ResponseMixins, IncidentMixins],
  components: {
    BOverlay,
    BBadge,
    BButton,
    SidebarTemplate,
    moment,
  },

  data() {
    return {
      showOverlay: false,
      incident: null,
    };
  },

  props: {
    incidentId: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getIncidentDetailsAndSetData(this.incidentId);
    },

    getIncidentDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getIncidentDetails(id)
        .then((res) => {
          console.log(res);
          this.incident = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>
