<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="Are you sure?" size="lg">
      <p>Are you sure to delete incident register?</p>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="outline-secondary"
        @click="hideModal">
        Cancel
      </b-button>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="danger" @click="deleteIncident">
        Delete
      </b-button>
    </b-modal>
    <b-sidebar id="sidebar-backdrop" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow lazy>
      <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
              <h4 class="font-weight-bolder">
                <span class="align-middle">Add An Incident</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" @click="closeSidebar()" variant="outline-secondary"><feather-icon size="24"
                  icon="XIcon" /></b-button>
            </div>
          </div>
          <div class="p-1">
            <validation-observer ref="observer">
              <b-form-group label="Name" label-for="defaultLabel">
                <template slot="label">
                  Name <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Name" rules="required">
                  <b-form-input id="defaultLabel" placeholder="Name" v-model="incidenttitle" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Name" label-for="defaultLabel">
                <template slot="label">
                  Description <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Description" rules="required">
                  <b-form-textarea id="textarea-default" placeholder="Enter Description" rows="3"
                    v-model="description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="row mt-1">
                <div class="col-6">
                  <b-form-group label-for="defaultLabel">
                    <template slot="label">
                      Incident Date <span class="text-danger">*</span>
                    </template>
                    <validation-provider #default="{ errors }" name="Incident Date" rules="required">
                      <flat-pickr v-model="incidentDate" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- {{userslist}} -->
                </div>
                <div class="col-6">
                  <b-form-group label-for="defaultLabel">
                    <template slot="label">
                      Closure Date <span class="text-danger">*</span>
                    </template>
                    <validation-provider #default="{ errors }" name="Closure Date" rules="required">
                      <flat-pickr v-model="closureDate" class="form-control" :config="{ minDate: incidentDate }" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <br />
              <b-form-group label-for="defaultLabel">
                <template slot="label">
                  Root Cause <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Root Cause" rules="required">
                  <b-form-textarea id="textarea-default" placeholder="Enter Root cause" rows="3" v-model="rootCause" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <br />

              <b-form-group label-for="defaultLabel">
                <template slot="label">
                  Learning From The Incident <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Learning" rules="required">
                  <b-form-textarea id="textarea-learning" placeholder="Enter Learning.." rows="3" v-model="learning" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <br />
              <b-form-group label-for="defaultLabel">
                <template slot="label">
                  Corrective Actions <span class="text-danger">*</span>
                </template>
                <!-- <label for="textarea-corrective-actions"
                  >Corrective Actions*</label
                > -->

                <div class="d-flex align-items-center justify-content-between w-100">
                  <b-form-input id="textarea-corrective-actions" placeholder="Enter Corrective Actions"
                    v-model="correctiveAction" />

                  <!-- <button
                    class="btn btn-primary btn-sm ml-1"
                    @click="AddCorrective(correctiveAction)"
                  >
                    Add
                  </button> -->
                  <b-button class="ml-1" variant="primary" size="md"
                    @click="AddCorrective(correctiveAction)">Add</b-button>
                </div>

                <ul class="mt-1">
                  <li v-for="bullet in this.correctiveBullets" :key="bullet">
                    {{ bullet }}
                  </li>
                </ul>
              </b-form-group>

              <b-form-group label-for="defaultLabel">
                <template slot="label">
                  Incident Owner <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Incident Owner" rules="required">
                  <select name="" id="" class="form-control" v-model="incidentOwner">
                    <option :value="user.user_id" v-for="user in userslist.data" :key="user">
                      {{ user.firstname }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <br />
              <label for="">Status</label>
              <select name="" id="" class="form-control mb-1" v-model="status">
                <option value="1">Open</option>
                <option value="2">Closed</option>
              </select>

              <button class="btn btn-success mr-1" @click="createIncidentRegister">
                Create
              </button>
              <button class="btn btn-secondary" @click="closeSidebar">
                Cancel
              </button>
            </validation-observer>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar id="sidebar-edit" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
              <h4 class="font-weight-bolder">
                <span class="align-middle">Update Incident </span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" @click="closeSidebarEdit()" variant="outline-secondary"><feather-icon size="24"
                  icon="XIcon" /></b-button>
            </div>
          </div>
          <div class="p-1">
            <validation-observer ref="observer2">
              <b-form-group label="Name" label-for="defaultLabel">
                <template slot="label">
                  Name <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Name" rules="required">
                  <b-form-input id="defaultLabel" placeholder="Name" v-model="incidenttitle" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Description" label-for="defaultLabel">
                <template slot="label">
                  Description <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Description" rules="required">
                  <b-form-textarea id="textarea-default" placeholder="Enter Description" rows="3"
                    v-model="description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="row mt-1">
                <div class="col-6">
                  <b-form-group label="Incident Date" label-for="defaultLabel">
                    <template slot="label">
                      Incident Date <span class="text-danger">*</span>
                    </template>
                    <validation-provider #default="{ errors }" name="Incident Date" rules="required">
                      <flat-pickr v-model="incidentDate" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- {{userslist}} -->
                </div>
                <div class="col-6">
                  <b-form-group label="Closure Date" label-for="defaultLabel">
                    <template slot="label">
                      Closure Date <span class="text-danger">*</span>
                    </template>
                    <validation-provider #default="{ errors }" name="Closure Date" rules="required">
                      <flat-pickr v-model="closureDate" class="form-control" :config="{ minDate: incidentDate }" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <br />
              <b-form-group label="Root Cause" label-for="defaultLabel">
                <template slot="label">
                  Root Cause <span class="text-danger">*</span>
                </template>
                <validation-provider #default="{ errors }" name="Root Cause" rules="required">
                  <b-form-textarea id="textarea-default" placeholder="Enter Root cause" rows="3" v-model="rootCause" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <br />
              <b-form-group label="Learning From The Incident" label-for="defaultLabel">
                <template slot="label">
                  Learning From The Incident <span class="text-danger">*</span>
                </template>

                <validation-provider #default="{ errors }" name="Learning" rules="required">
                  <b-form-textarea id="textarea-learning" placeholder="Enter Learning.." rows="3" v-model="learning" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <br />
              <b-form-group label="Corrective Actions" label-for="defaultLabel">
                <template slot="label">
                  Corrective Actions <span class="text-danger">*</span>
                </template>

                <div class="d-flex">
                  <b-form-input id="textarea-default" placeholder="Enter Corrective Actions"
                    v-model="correctiveAction" />
                  <b-button class="ml-1" variant="primary" size="md"
                    @click="AddCorrective(correctiveAction)">Add</b-button>
                </div>
              </b-form-group>

              <ul class="mt-1">
                <li v-for="bullet in this.correctiveBullets" :key="bullet">
                  {{ bullet }}
                </li>
              </ul>

              <b-form-group label="Incident Owner" label-for="defaultLabel">
                <template slot="label">
                  Incident Owner <span class="text-danger">*</span>
                </template>

                <select name="" id="" class="form-control" v-model="incidentOwner">
                  <option :value="user.user_id" v-for="user in this.userslist.data" :key="user">
                    {{ user.firstname }}
                  </option>
                </select>
              </b-form-group>

              <label for="">Status</label>
              <select name="" id="" class="form-control mb-1" v-model="status">
                <option value="1">Open</option>
                <option value="2">Closed</option>
              </select>

              <button class="btn btn-primary mr-1" @click="updateIncidentRegister">
                Update
              </button>
              <button class="btn btn-secondary" @click="closeSidebarEdit">
                Cancel
              </button>
            </validation-observer>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
    <b-sidebar id="sidebar-applicable" sidebar-class="sidebar-lg" bg-variant="white" backdrop right no-header shadow>
      <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
        <div class="card" style="box-shadow: none !important">
          <div class="card-header border-bottom d-flex">
            <div style="width: 70%" class="d-flex flex-column justify-content-center align-items-start">
              <h4 class="font-weight-bolder">
                <span class="align-middle">Applicability Review</span>
              </h4>
              <p class="card-subtitle text-muted pt-75"></p>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-button size="sm" @click="closeApplicable()" variant="outline-secondary"><feather-icon size="24"
                  icon="XIcon" /></b-button>
            </div>
          </div>

          <div class="p-1">
            <b-form-group label="Applicability" label-for="defaultLabel">
              <select name="" id="" class="form-control" v-model="applicability">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </b-form-group>
            <b-form-group label="Applicability Note" label-for="defaultLabel">
              <b-form-textarea id="textarea-default" placeholder="Enter Note" rows="3" v-model="applicability_note" />
            </b-form-group>
            <b-form-group label="Assessment Date" label-for="defaultLabel">
              <flat-pickr v-model="assessmentDate" class="form-control" />
            </b-form-group>

            <button class="btn btn-primary mr-1" @click="AddReview">
              Add Review
            </button>
            <button class="btn btn-secondary">Cancel</button>
          </div>
        </div>
      </b-overlay>
    </b-sidebar>

    <b-sidebar id="sidebar-incident-details" bg-variant="white" backdrop right no-header shadow lazy width="580px">
      <incident-details :closeAction="closeIncidentDetailsSidebar" :incidentId="selectedIncidentId" />
    </b-sidebar>

    <b-overlay opacity="0.17" blur="1rem" :show="showOverlay" rounded="md" variant="secondary">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <b-button @click="openSidebar" class="mb-75" variant="success"><feather-icon class="mr-75"
                icon="PlusIcon" />Add
              An Incident
            </b-button>
          </div>

          <table role="table" class="table table-hover mt-1">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Incident No.</th>
                <th role="columnheader" scope="col">Name</th>
                <th role="columnheader" scope="col">Incident Date</th>
                <th role="columnheader" scope="col">Closure Date</th>
                <th role="columnheader" scope="col">Incident Owner</th>
                <th role="columnheader" scope="col">Status</th>
                <th role="columnheader" scope="col">Action</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="incidentlist&&incidentlist.length>0">
                <tr v-for="incident in this.incidentlist" :key="incident._id"
                  @click="handleIncidentClick(incident._id)">
                  <td>{{ incident.incident_register_number }}</td>
                  <td>{{ incident.title }}</td>
                  <td>{{ incident.incident_date | moment }}</td>
                  <td>{{ incident.closure_date | moment }}</td>
                  <td>
                    <b-badge v-if="typeof incident.incident_owner == 'undefined'" variant="danger">Deleted
                      User</b-badge>
                    <span v-else-if="incident.incident_owner == null"></span>
                    <span v-else class="d-flex">
                      <span class="mr-1" v-if="incident.incident_owner.firstname">{{ incident.incident_owner.firstname
                        }}</span>
                      <span v-if="incident.incident_owner.lastname">{{
        incident.incident_owner.lastname
      }}</span>
                    </span>
                  </td>
                  <td>
                    <b-badge variant="primary" v-if="incident.status == 1">
                      Open
                    </b-badge>
                    <b-badge variant="secondary" v-if="incident.status == 2">
                      Closed
                    </b-badge>
                  </td>
                  <td>
                    <div class="d-flex align-items-center justify-content-start">
                      <button class="btn btn-primary mr-50 btn-sm" @click.stop="openEditLegal(incident._id)">
                        Edit
                      </button>
                      <button class="btn btn-danger btn-sm" @click.stop="showModal(incident._id)">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                    <empty-table-section title="Incident Not Found">
                      <template #content>
                        <p class="font-weight-bold text-center">
                          It seems like there are no Incident Registers at the moment.
                        </p>
                      </template>
                    </empty-table-section>
              </template>
              <!-- {{this.legallist}} -->
            </tbody>
          </table>

          <div class="mt-3">
            <div class="row">
              <div class="d-flex align-items-center justify-content-center col-12">
                <b-pagination v-model="filters.page" :per-page="pagination.perPage" :total-rows="pagination.rows"
                  aria-controls="my-table"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BAvatar,
  BCollapse,
  BBadge,
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  VBToggle,
  VBTooltip,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import RiskAssessmentMixins from "@//mixins/RiskAssessmentMixins";
import ResponseMixins from "@//mixins/ResponseMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DonutBar from "@/components/DonutBar.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import IncidentDetails from "./components/IncidentDetails.vue";
import moment from "moment";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  components: {
    BPagination,
    BButton,
    BSidebar,
    BFormInput,
    BOverlay,
    BBadge,
    BAvatar,
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    DonutBar,
    flatPickr,
    ToastificationContent,
    BListGroup,
    BListGroupItem,
    IncidentDetails,
    moment,
    EmptyTableSection
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      selectedIncidentId: null,
      showOverlay: false,
      pagination: {
        lastPage: null,
        perPage: 10,
        rows: 1,
      },
      filters: {
        page: 1,
      },
      newRiskAssessment: {
        title: null,
        notes: null,
      },
      assessments: null,
      dateDefault: null,
      title: "",
      content: "",
      authority: "",
      internalpoc: "",
      type: "",
      country: "",
      userslist: [],
      legallist: [],
      legalid: "",
      legaldetails: [],
      authorities: [],
      applicability: "",
      applicability_note: "",
      assessmentDate: "",
      countries: [],
      industries: [],
      countryy: "",
      industryy: "",
      incidenttitle: "",
      description: "",
      incidentDate: "",
      closureDate: "",
      rootCause: "",
      learning: "",
      correctiveAction: "",
      incidentOwner: "",
      status: "",
      correctiveBullets: [],
      incidentlist: [],
      required,
    };
  },
  mixins: [ResponseMixins, RiskAssessmentMixins, UtilsMixins],
  watch: {
    filters: {
      handler: function (newValue) {
        this.getRiskAssessmentsAndSetData(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
    // this.getAuthority();
    this.getCountry();
    this.getIndustry();
  },
  methods: {
    handleIncidentClick(id) {
      this.selectedIncidentId = id;
      this.openIncidentDetailsSidebar();
    },

    openIncidentDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-incident-details");
    },

    closeIncidentDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-incident-details");
    },

    AddCorrective(correctiveAction) {
      this.correctiveBullets.push(correctiveAction);
      this.correctiveAction = "";
    },
    getCountry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/countries`,
      };
      this.$http(options)
        .then((res) => {
          this.countries = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIndustry() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/industries`,
      };
      this.$http(options)
        .then((res) => {
          this.industries = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddReview() {
      const data = {
        applicability: this.applicability,
        applicability_note: this.applicability_note,
        assessment_date: this.assessmentDate,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/legal-registers/${this.legalid}/assessments`,
      };
      this.$http(options)
        .then((res) => {
          //   this.sidebar_bulkupdate = false;
          this.closeApplicable();
          this.getLegalList();
          this.applicability = "";
          this.applicability_note = "";
          this.assessmentDate = "";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ApplicabilityReview(legalid) {
      this.legalid = legalid;
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
    },
    closeApplicable() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-applicable");
    },
    viewLegalRegister(legalid) {
      this.$router.push({
        name: "legalassessment",
        params: { id: legalid },
      });
    },
    getAuthority() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSFURL + `/authorities`,
      };
      this.$http(options)
        .then((res) => {
          this.authorities = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      //   this.getRiskAssessmentsAndSetData(this.filters);
      this.getUsers();
      this.getIncidentList();
    },
    createIncidentRegister() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          const data = {
            title: this.incidenttitle,
            description: this.description,
            incident_date: this.incidentDate,
            closure_date: this.closureDate,
            cause: this.rootCause,
            learning: this.learning,
            corrective_actions: this.correctiveBullets,
            incident_owner_user_id: this.incidentOwner,
            status: this.status,
          };
          const options = {
            method: "POST",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_BASEURL + `/incident-registers`,
          };
          this.$http(options)
            .then((res) => {
              //   this.sidebar_bulkupdate = false;
              this.closeSidebar();
              this.getIncidentList();
              // this.getLegalList();
              // this.title = "";
              // this.content = "";
              // this.dateDefault = "";
              // this.authority = "";
              // this.internalpoc = "";
              // this.type = "";
              // this.country = "";
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.response.data.errors.corrective_actions[0],
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    updateIncidentRegister() {
      this.$refs.observer2.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          const data = {
            title: this.incidenttitle,
            description: this.description,
            incident_date: this.incidentDate,
            closure_date: this.closureDate,
            cause: this.rootCause,
            learning: this.learning,
            corrective_actions: this.correctiveBullets,
            incident_owner_user_id: this.incidentOwner,
            status: this.status,
          };
          const options = {
            method: "PUT",
            data: data,
            headers: { "content-type": "application/json" },
            url:
              process.env.VUE_APP_BASEURL +
              `/incident-registers/${this.legalid}`,
          };
          this.$http(options)
            .then((res) => {
              //   this.sidebar_bulkupdate = false;
              this.closeEditLegal();
              this.getIncidentList();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/users?dont_paginate=true`,
      };
      this.$http(options)
        .then((res) => {
          this.userslist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIncidentList() {
      this.showOverlay = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + `/incident-registers`,
      };
      this.$http(options)
        .then((res) => {
          this.incidentlist = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getLegalDetails() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + `/incident-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.legaldetails = res.data.data;
          this.incidenttitle = this.legaldetails.title;
          this.description = this.legaldetails.description;
          this.incidentDate = this.legaldetails.incident_date;
          this.closureDate = this.legaldetails.closure_date;
          this.rootCause = this.legaldetails.cause;
          this.learning = this.legaldetails.learning;
          this.correctiveBullets = this.legaldetails.corrective_actions;
          this.incidentOwner = this.legaldetails.incident_owner_user_id;
          this.status = this.legaldetails.status;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteIncident() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + `/incident-registers/${this.legalid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getIncidentList();
          this.hideModal();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditLegal(id) {
      this.legalid = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
      this.getLegalDetails();
    },
    closeEditLegal() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },

    handleRowClick(assessmentId) {
      this.$router.push(`/riskassessment/${assessmentId}/risks`);
    },

    getRiskAssessmentsAndSetData(params) {
      this.getRiskAssessments(params)
        .then((res) => {
          this.assessments = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.perPage = res.data.data.per_page;
          this.pagination.rows = res.data.data.last_page * 10;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleNewRiskAssessmentModalSubmit() {
      this.storeRiskAssessment({
        title: this.newRiskAssessment.title,
        notes: this.newRiskAssessment.notes,
      })
        .then((res) => {
          this.load();
          this.handleResponse(res);
          // console.log("created", res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.hideModal();
        });
    },

    handleNewRiskAssessmentClick() {
      this.newRiskAssessment.title = null;
      this.newRiskAssessment.notes = null;
      this.showModal();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal(id) {
      this.legalid = id;
      this.$refs["my-modal"].show();
    },
    // closeSidebar: function () {
    //   this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    // },
    openSidebar: function () {
      this.incidenttitle = "";
      this.description = "";
      this.incidentDate = "";
      this.closureDate = "";
      this.rootCause = "";
      this.learning = "";
      this.correctiveBullets = [];
      this.incidentOwner = "";
      this.status = "";

      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    closeSidebar: function () {
      this.incidenttitle = "";
      this.description = "";
      this.incidentDate = "";
      this.closureDate = "";
      this.rootCause = "";
      this.learning = "";
      this.correctiveBullets = [];
      this.incidentOwner = "";
      this.status = "";

      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    closeSidebarEdit: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },
    openSidebarEdit: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-edit");
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="css" scoped>
.table td,
.table th {
  width: 10%;
  text-transform: capitalize !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
th {
  text-transform: capitalize !important;
}
</style>
